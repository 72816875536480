// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app'
import {getAnalytics} from 'firebase/analytics'
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {initializeAppCheck, ReCaptchaV3Provider} from 'firebase/app-check'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBFIBlmn-fmy6aosqM3LsimK6_Ex2yL22E',
  authDomain: 'cafi-pago.firebaseapp.com',
  projectId: 'cafi-pago',
  storageBucket: 'cafi-pago.firebasestorage.app',
  messagingSenderId: '1060234955889',
  appId: '1:1060234955889:web:1ffbc1b02d8029416adfd0',
  measurementId: 'G-K4D24Y42B4',
}

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig)
if (document.URL.includes('127.0.0.1') || document.URL.includes('localhost'))
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true
initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider('6LcFnu0qAAAAAJeGDjh_1b5KWNVYlRKP7ZKLDra4'),
  isTokenAutoRefreshEnabled: true,
})
export const analytics = getAnalytics(firebaseApp)
export const auth = getAuth(firebaseApp)
export const db = getFirestore(firebaseApp)
