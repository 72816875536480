import {db} from '../firebase'

import {collection, query, where, getDocs} from 'firebase/firestore'

import moment from 'moment'

export function compGetMenu() {
  async function getAllMenu() {
    const querySnapshot = await getDocs(collection(db, 'menu'))
    let menu = []
    querySnapshot.forEach(doc => {
      let m = doc.data()
      m.id = doc.id
      menu.push(m)
    })
    return menu.sort((a, b) => {
      if (a.date < b.date) return 1
      if (a.date > b.date) return -1
      return 0
    })
  }
  async function getMenuByDate(date) {
    const menuRef = collection(db, 'menu')
    const q = query(menuRef, where('date', '==', date))
    const querySnapshot = await getDocs(q)
    let menu = []
    querySnapshot.forEach(doc => {
      menu.push(doc.data())
    })
    return menu[0]
  }
  async function getCurrentWeekMenu() {
    let today = moment()
    if (today.weekday() == 6) today = moment(today).add(2, 'days')
    if (today.weekday() == 0) today = moment(today).add(1, 'days')
    const weekStart = today.clone().startOf('isoWeek').format('YYYY/MM/DD')
    const weekEnd = moment(weekStart).add(4, 'days').format('YYYY/MM/DD')
    const q = query(
      collection(db, 'menu'),
      where('date', '>=', today.format('YYYY/MM/DD')),
      where('date', '<=', weekEnd)
    )
    const querySnapshot = await getDocs(q)
    let weekMenu = []
    querySnapshot.forEach(menu => weekMenu.push(menu.data()))
    return weekMenu
  }
  return {
    getMenuByDate,
    getAllMenu,
    getCurrentWeekMenu,
  }
}
