<template>
  <q-page
    class="text-white"
    style="background-image: url('https://cafipago.com/pattern.png')"
  >
    <div class="absolute-center full-width">
      <div class="text-h2 text-center cp-500 q-mb-md">
        <q-icon name="restaurant_menu" size="2.5em" />
      </div>
      <div class="text-h2 text-center cp-500 q-mb-md">CafiPago</div>
      <div class="text-h6 text-center cp-500 q-mb-md">Bienvenido</div>
      <div class="row justify-center">
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-5 col-xs-10 q-pa-sm">
          <q-btn
            label="Iniciar sesión"
            to="/login"
            class="full-width cp-mulish cp-700"
            unelevated
            text-color="blue-8"
            color="white"
            rounded
            no-caps
          />
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-5 col-xs-10 q-pa-sm">
          <q-btn
            label="Registro"
            to="/register"
            outline
            rounded
            no-caps
            class="full-width cp-mulish cp-700"
          />
        </div>
      </div>
    </div>
  </q-page>
</template>
