import {auth} from '../firebase'
import {
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from 'firebase/auth'

import {compGetUserData} from '@/composables/getUserData'

const {getUserData} = compGetUserData()

export function compAuth() {
  async function loginUser(email, pass) {
    try {
      await signInWithEmailAndPassword(auth, email, pass)
    } catch (err) {
      return err.code
    }
  }
  async function logoutUser() {
    await signOut(auth)
    sessionStorage.removeItem('cpUserType')
  }
  async function getUserType(uid) {
    const userData = await getUserData(uid)
    return userData.type
  }
  async function sendPasswordReset(email) {
    try {
      const resetResp = await sendPasswordResetEmail(auth, email)
      return resetResp
    } catch (err) {
      return err.code
    }
  }
  return {
    loginUser,
    logoutUser,
    getUserType,
    sendPasswordReset,
  }
}
