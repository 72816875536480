import {db} from '../firebase'

import {doc, updateDoc} from 'firebase/firestore'

export function compUpdateInventory() {
  async function updateInventoryItem(itemData) {
    let data = {
      name: itemData.name,
      price: parseFloat(itemData.price),
      category: itemData.category,
    }
    const itemRef = doc(db, 'inventory', itemData.barcode)
    await updateDoc(itemRef, data)
    data.barcode = itemData.barcode
    return data
  }
  return {
    updateInventoryItem,
  }
}
