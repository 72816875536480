import {db} from '../firebase'

import {doc, deleteDoc} from 'firebase/firestore'

export function compDeleteMenu() {
  async function deleteMenu(id) {
    await deleteDoc(doc(db, 'menu', id))
    return id
  }
  return {
    deleteMenu,
  }
}
