import {db} from '../firebase'

import {doc, updateDoc} from 'firebase/firestore'

export function compUpdateStudents() {
  async function updateStudentBalance(id, newBalance) {
    const studentRef = doc(db, 'students', id)
    await updateDoc(studentRef, {
      balance: parseFloat(newBalance),
    })
  }
  async function updateStudentFingerprintTemplate(id, template) {
    const studentRef = doc(db, 'students', id)
    await updateDoc(studentRef, {
      fpTemplate: template,
    })
  }
  async function updateStudentCreditStatus(status, id) {
    try {
      const studentRef = doc(db, 'students', id)
      await updateDoc(studentRef, {
        allowCredit: status,
      })
      return 'ok'
    } catch (err) {
      return 'err'
    }
  }
  async function updateStudentRFIDTag(id, tagId) {
    const studentRef = doc(db, 'students', id)
    await updateDoc(studentRef, {
      rfidTag: `${tagId}`,
    })
    return 'ok'
  }
  return {
    updateStudentBalance,
    updateStudentFingerprintTemplate,
    updateStudentCreditStatus,
    updateStudentRFIDTag,
  }
}
