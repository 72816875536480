import {db} from '../firebase'

import {collection, query, where, getDocs} from 'firebase/firestore'

export function compGetOrders() {
  async function getAllOrders() {
    const querySnapshot = await getDocs(collection(db, 'orders'))
    let orders = []
    querySnapshot.forEach(doc => {
      let data = doc.data()
      orders.push({
        student: data.studentFullName,
        amount: `$${data.total.toFixed(2)}`,
        purchaseDate: data.purchaseDate,
        deliveryDate: data.deliveryDate,
        deliveryTime: data.deliveryTime,
        deliveryPlace: data.deliveryPlace,
        cart: data.cart,
        id: doc.id,
        status: data.status,
      })
    })
    return orders.sort((a, b) => {
      if (a.deliveryDate < b.deliveryDate) return 1
      if (a.deliveryDate > b.deliveryDate) return -1
      return 0
    })
  }
  async function getParentOrders(id) {
    let orders = []
    const ordersRef = collection(db, 'orders')
    const q = query(ordersRef, where('parent.id', '==', id))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach(order => {
      let o = order.data()
      orders.push({
        student: o.studentFullName,
        total: o.total,
        purchaseDate: o.purchaseDate,
        deliveryDate: o.deliveryDate,
        cart: o.cart,
        status: o.status,
        deliveryPlace: o.deliveryPlace,
        deliveryTime: o.deliveryTime,
      })
    })
    return orders
  }
  return {
    getAllOrders,
    getParentOrders,
  }
}
