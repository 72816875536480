import {db} from '../firebase'

import {collection, getDoc, getDocs, doc} from 'firebase/firestore'

export function compGetInventory() {
  async function getAllInventory() {
    const querySnapshot = await getDocs(collection(db, 'inventory'))
    let items = []
    querySnapshot.forEach(doc => {
      let i = doc.data()
      i.barcode = doc.id
      items.push(i)
    })
    return items.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      return 0
    })
  }
  async function getInventoryItemById(id) {
    const docRef = doc(db, 'inventory', id)
    const docSnap = await getDoc(docRef)
    let data
    if (docSnap.exists()) {
      data = docSnap.data()
      data.id = docSnap.id
    } else data = 'no-exists'

    return data
  }

  return {
    getAllInventory,
    getInventoryItemById,
  }
}
