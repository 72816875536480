import {createApp} from 'vue'
import Quasar from 'quasar/src/vue-plugin.js';
import router from './router/index'
import App from './App.vue'

import {getApp} from 'firebase/app'
import {getFunctions, connectFunctionsEmulator} from 'firebase/functions'

import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/material-icons-round/material-icons-round.css'
import '@quasar/extras/fontawesome-v6/fontawesome-v6.css'
import 'quasar/src/css/index.sass'
import Notify from 'quasar/src/plugins/Notify.js';

// if (document.URL.includes('127.0.0.1') || document.URL.includes('localhost')) {
//   const functions = getFunctions(getApp())
//   connectFunctionsEmulator(functions, 'localhost', 5001)
// }

const app = createApp(App)

app.use(router)

app.use(Quasar, {
  plugins: {Notify}, // import Quasar plugins and add here
})

app.mount('#app')
