import {db} from '../firebase'

import {collection, addDoc} from 'firebase/firestore'

import moment from 'moment'

import {compGetStudents} from '@/composables/getStudents'
import {compUpdateStudents} from '@/composables/updateStudents'

const {getStudentById} = compGetStudents()
const {updateStudentBalance} = compUpdateStudents()

export function compPostOrder() {
  async function postOrder(student, cart, place, date, time) {
    // 1. get student data
    let studentData = await getStudentById(student)
    // 2. check if student has enough balance
    let total = 0
    cart.forEach(item => {
      total += item.price * item.amount
    })
    if (studentData.balance < total) return 'no-balance'
    // 3. create order object
    let orderObject = {
      purchaseDate: moment().format('YYYY/MM/DD'),
      deliveryDate: date,
      deliveryTime: time,
      deliveryPlace: place,
      parent: {
        fullName: `${studentData.parentName} ${studentData.parentLastname}`,
        id: studentData.parentId,
      },
      studentFullName: `${studentData.name} ${studentData.lastName}`,
      studentId: student,
      total: total,
      cart: cart,
      status: 'delivery',
    }
    // 4. post order
    await addDoc(collection(db, 'orders'), orderObject)
    // 5. create sale object
    let saleObject = {
      total: total,
      studentId: student,
      parentId: studentData.parentId,
      studentName: studentData.name,
      studentLastName: studentData.lastName,
      paymentMethod: 'order',
      date: moment().format('YYYY/MM/DD'),
      cart: cart,
    }
    // 6. post sale
    await addDoc(collection(db, 'sales'), saleObject)
    // 7. reduce student balance
    let newBalance = studentData.balance - total
    await updateStudentBalance(student, newBalance)
    // 8. return ok
    return 'ok'
  }
  return {
    postOrder,
  }
}
