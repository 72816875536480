import {firebaseApp, db, auth} from '../firebase'

import {getStorage, ref, uploadBytes, getDownloadURL} from 'firebase/storage'
import {collection, addDoc} from 'firebase/firestore'
import {getFunctions, httpsCallable} from 'firebase/functions'

import {compGetUserData} from '@/composables/getUserData'
import {compGetRecharges} from '@/composables/getRecharges'

import moment from 'moment'

const {getUserData} = compGetUserData()
const {getRechargeById} = compGetRecharges()
const functions = getFunctions(firebaseApp)

export function compPostRecharges() {
  async function postParentRecharges(rechargeData, img) {
    // 0. user uid
    const uid = auth.currentUser.uid
    // 1. post image
    const storage = getStorage()
    const path = `rechargesImgs/${uid}/${img.lastModified}-${img.name}`
    const rechargeImageRef = ref(storage, path)
    await uploadBytes(rechargeImageRef, img)
    const downloadUrl = await getDownloadURL(ref(storage, path))
    // 2. get parent data
    const userData = await getUserData(uid)
    // 3. create recharge object
    rechargeData.students.forEach(student => {
      student.amount = parseFloat(student.amount)
    })
    let recharge = {
      status: 'pending',
      owner: uid,
      ownerFullName: `${userData.name} ${userData.lastName}`,
      students: rechargeData.students,
      amount: parseFloat(rechargeData.amount),
      img: downloadUrl,
      date: moment().format('YYYY/MM/DD'),
      email: auth.currentUser.email,
    }
    // 4. post new recharge
    const docRef = await addDoc(collection(db, 'recharges'), recharge)
    // 5. send new recharge email
    const newRechargeEmail = httpsCallable(functions, 'newRechargeEmail')
    newRechargeEmail()
    // 6. return new recharge
    recharge = await getRechargeById(docRef.id)
    return recharge
  }
  return {
    postParentRecharges,
  }
}
