<template>
  <q-page>
    <div class="row">
      <div
        class="col-xl-7 col-lg-7 col-md-7 col-sm-4 col-xs-12"
        v-if="screenSize != 'xs'"
      >
        <div class="row justify-center items-center" style="height: 100vh">
          <div class="text-h4 cp-700">CafiPago</div>
        </div>
      </div>
      <div
        class="col-xl-5 col-lg-5 col-md-5 col-sm-8 col-xs-12 shadow-4"
        style="background-image: url('https://cafipago.com/pattern.png')"
      >
        <div class="row justify-center items-center" style="height: 100vh">
          <div class="col-lg-8 col-xs-10">
            <q-card flat class="cp-rounded-borders">
              <q-card-section>
                <div class="text-h5 cp-700">Iniciar sesión</div>
              </q-card-section>
              <q-separator />
              <q-card-section>
                <q-input
                  label="Correo"
                  filled
                  class="q-mb-sm"
                  v-model="email"
                  @keydown.enter="sendLogin"
                  type="email"
                />

                <q-input
                  label="Contraseña"
                  filled
                  v-model="pass"
                  :type="isPwd ? 'password' : 'text'"
                  @keydown.enter="sendLogin"
                >
                  <template v-slot:append>
                    <q-icon
                      :name="isPwd ? 'visibility_off' : 'visibility'"
                      class="cursor-pointer"
                      @click="isPwd = !isPwd"
                    />
                  </template>
                </q-input>
              </q-card-section>
              <q-separator />
              <q-card-actions class="q-px-md">
                <q-btn
                  label="Iniciar sesion"
                  class="full-width q-mb-sm cp-700"
                  no-caps
                  unelevated
                  color="blue-8"
                  rounded
                  :loading="loading"
                  :disable="
                    loading || !email || !pass || !validEmail.test(email)
                  "
                  @click="sendLogin"
                />
                <q-btn
                  label="Registrar"
                  class="full-width cp-700 q-mb-sm"
                  outline
                  to="/register"
                  color="blue-8"
                  no-caps
                  rounded
                />
                <q-btn
                  label="Olvide mi contraseña"
                  class="full-width cp-700"
                  flat
                  color="blue-8"
                  no-caps
                  rounded
                  @click="resetPassDialog = true"
                />
              </q-card-actions>
            </q-card>
          </div>
        </div>
      </div>
    </div>
  </q-page>
  <q-dialog v-model="resetPassDialog">
    <q-card
      style="width: 500px; max-width: 80vw"
      flat
      class="cp-rounded-borders"
    >
      <q-card-section>
        <div class="text-h6 cp-700">Olvidé mi contraseña</div>
      </q-card-section>
      <q-separator />
      <q-card-section>
        <q-input
          v-model="resetEmail"
          label="Ingresa tu correo electrónico"
          filled
        />
      </q-card-section>
      <q-separator />
      <q-card-actions align="right">
        <q-btn
          label="Enviar"
          unelevated
          color="blue-8"
          class="cp-700 cp-rounded-borders"
          no-caps
          :disable="
            !resetEmail.trim() || !validEmail.test(resetEmail) || loadingReset
          "
          :loading="loadingReset"
          @click="sendReset"
        />
        <q-btn
          label="Cancelar"
          class="cp-700 cp-rounded-borders"
          no-caps
          unelevated
          color="red-8"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import {ref, computed} from 'vue'
import {useQuasar} from 'quasar'

import {compAuth} from '@/composables/auth'

const $q = useQuasar()

const {loginUser, sendPasswordReset} = compAuth()

const email = ref('')
const pass = ref('')
const loading = ref(false)
const loadingReset = ref(false)
const validEmail =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const resetPassDialog = ref(false)
const resetEmail = ref('')
const isPwd = ref(true)

const sendLogin = async () => {
  if (!email.value || !pass.value || !validEmail.test(email.value)) return
  loading.value = true
  let errMsg
  const loginResp = await loginUser(email.value, pass.value)
  if (loginResp == 'auth/user-not-found') errMsg = 'Usuario no registrado'
  if (loginResp == 'auth/wrong-password') errMsg = 'Contraseña incorrecta'
  if (errMsg)
    $q.notify({
      message: errMsg,
      color: 'red-8',
      position: 'top',
      badgeColor: 'yellow-8',
      progress: true,
      timeout: 3000,
    })
  loading.value = false
}

const sendReset = async () => {
  loadingReset.value = true
  let msg
  let resetResp = await sendPasswordReset(resetEmail.value)
  if (resetResp == 'auth/user-not-found') msg = 'Usuario no encontrado'
  $q.notify({
    message: msg ? msg : 'Solicitud enviada con éxito',
    color: msg ? 'red-8' : 'green-8',
    position: 'top',
    badgeColor: 'yellow-8',
    progress: true,
    timeout: 3000,
  })
  loadingReset.value = false
  if (!msg) resetPassDialog.value = false
}

const screenSize = computed(() => {
  return $q.screen.name
})
</script>
