<template>
  <div id="app" class="bg-grey-2">
    <router-view />
  </div>
</template>

<script setup>
import {auth} from './firebase'

import {onAuthStateChanged} from 'firebase/auth'

import {compAuth} from '@/composables/auth'

import {useRoute, useRouter} from 'vue-router'

const router = useRouter()
const route = useRoute()

const {getUserType} = compAuth()

onAuthStateChanged(auth, async user => {
  if (user) {
    const type = await getUserType(user.uid)
    sessionStorage.setItem('cpUserType', type)
    if (
      route.fullPath == '/' ||
      route.fullPath == '/login' ||
      route.fullPath == '/register'
    ) {
      if (type == 'parent') router.push('/parents/home')
      if (type == 'admin' || type == 'cashier') router.push('/admin/pos')
    }
  }
})
</script>

<style>
@import './style/style.css';
</style>
