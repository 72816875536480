<template>
  <q-page class="text-grey=10">
    <div class="q-pa-md q-mb-md">
      <div class="text-h6 cp-300">Bienvenido</div>
      <div class="text-h4 cp-700">
        {{ `${userData.name} ${userData.lastName}` }}
      </div>
    </div>
    <div class="q-px-md">
      <q-badge rounded color="blue-8" class="q-px-md">
        <q-icon
          name="restaurant_menu"
          color="white"
          size="2em"
          class="q-mr-sm"
        />
        <span class="text-h6 cp-500">Menú de la semana</span>
      </q-badge>
    </div>
    <div class="q-pa-md">
      <q-carousel
        v-model="slide"
        transition-prev="slide-right"
        transition-next="slide-left"
        swipeable
        animated
        control-color="blue-8"
        navigation
        arrows
        padding
        infinite
        class="cp-rounded-borders"
        height="auto"
        v-if="weekMenu.length > 0"
      >
        <q-carousel-slide
          v-for="(menuItem, i) in weekMenu"
          :name="i + 1"
          :key="i"
        >
          <div class="row items-center q-gutter-md q-col-gutter">
            <div
              class="col-xl-3 col-lg-3 col-md-4 col-xs-12"
              v-if="menuItem.img"
            >
              <q-img class="cp-rounded-borders" :src="menuItem.img" />
            </div>
            <div class="col-xl-4 col-lg-4 col-xs-12">
              <div class="text-caption cp-mulish cp-300">
                {{ formatDate(menuItem.date) }}
              </div>
              <div class="text-h4 cp-700 q-mb-sm">{{ menuItem.name }}</div>
              <div class="text-subtitle2 cp-mulish cp-200 q-mb-sm">
                {{ menuItem.description }}
              </div>
              <!-- <div class="text-subtitle2 cp-700">
                Precio prescolar:
                <span class="text-blue-8 text-h6 cp-700">
                  ${{ menuItem.babyPrice.toFixed(2) }}
                </span>
              </div> -->
              <div class="text-subtitle2 cp-700">
                Precio niño:
                <span class="text-blue-8 text-h6 cp-700">
                  ${{ menuItem.kidPrice.toFixed(2) }}
                </span>
              </div>
              <div class="text-subtitle2 cp-700">
                Precio adulto:
                <span class="text-blue-8 text-h6 cp-700">
                  ${{ menuItem.adultPrice.toFixed(2) }}
                </span>
              </div>
            </div>
          </div>
        </q-carousel-slide>
      </q-carousel>
      <div
        class="text-h5 cp-700 text-center q-py-xl text-grey-5"
        v-if="weekMenu.length == 0 && !loadingMenu"
      >
        Aun no hay menú para esta semana.
      </div>
    </div>
  </q-page>
</template>

<script setup>
import {ref, onBeforeMount} from 'vue'

import {auth} from '../../firebase'

import {onAuthStateChanged} from 'firebase/auth'

import {compGetUserData} from '@/composables/getUserData'
import {compGetMenu} from '@/composables/getMenu'
import {compDataFormat} from '@/composables/dataFormat'

const {getUserData} = compGetUserData()
const {getCurrentWeekMenu} = compGetMenu()
const {formatDate} = compDataFormat()

const slide = ref(1)
const userData = ref({
  name: '',
  lastName: '',
})
const weekMenu = ref([])
const loadingMenu = ref(true)

onBeforeMount(async () => {
  const menuResp = await getCurrentWeekMenu()
  weekMenu.value = menuResp
  loadingMenu.value = false
})

onAuthStateChanged(auth, async user => {
  if (user) {
    let uData = await getUserData(user.uid)
    userData.value.name = uData.name
    userData.value.lastName = uData.lastName
  }
})
</script>
