import {db} from '../firebase'

import {doc, updateDoc} from 'firebase/firestore'
import {getStorage, ref, uploadBytes, getDownloadURL} from 'firebase/storage'

export function compUpdateMenu() {
  async function updateMenuData(menu, img) {
    // 1. create update menu object
    let updatedData = {
      adultPrice: parseFloat(menu.adultPrice),
      date: menu.date,
      description: menu.description,
      kidPrice: parseFloat(menu.kidPrice),
      babyPrice: parseFloat(menu.babyPrice),
      name: menu.name,
      img: menu.img,
    }
    // 2. if update image, upload and get download url
    if (img) {
      const storage = getStorage()
      const path = `menuImgs/${img.lastModified}-${img.name}`
      const menuImageRef = ref(storage, path)
      await uploadBytes(menuImageRef, img)
      const downloadUrl = await getDownloadURL(ref(storage, path))
      updatedData.img = downloadUrl
    }
    // 3. if update image, add download url to object
    // 4. update document in db
    const menuRef = doc(db, 'menu', menu.id)
    await updateDoc(menuRef, updatedData)
    updatedData.id = menu.id
    return updatedData
  }
  return {
    updateMenuData,
  }
}
