<template>
  <q-page>
    <div class="q-pa-md">
      <div class="text-h4 cp-700">Pedidos</div>
      <div class="text-body2 cp-300">
        Tus pedidos para retirar en la semana.
      </div>
      <div class="row justify-end">
        <q-btn
          label="Nuevo pedido"
          rounded
          color="green-8"
          unelevated
          no-caps
          icon-right="add"
          class="q-px-lg cp-700"
          @click="openNewOrderView"
        />
      </div>
    </div>
    <div class="q-px-md">
      <q-table
        title="Historial de pedidos"
        :rows="orders"
        :columns="columns"
        row-key="name"
        flat
        class="cp-rounded-borders"
        :pagination="{rowsPerPage: 25}"
        :loading="ordersLoading"
      >
        <template v-slot:header="props">
          <q-tr :props="props">
            <q-th auto-width />
            <q-th v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.label }}
            </q-th>
          </q-tr>
        </template>

        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td auto-width>
              <q-btn
                size="sm"
                color="blue-8"
                round
                dense
                unelevated
                @click="props.row.expand = !props.row.expand"
                :icon="props.row.expand ? 'remove' : 'add'"
              />
            </q-td>
            <q-td key="student" :props="props">
              {{ props.row.student }}
            </q-td>
            <q-td key="purchaseDate" :props="props">
              {{ formatDate(props.row.purchaseDate) }}
            </q-td>
            <q-td key="deliveryDate" :props="props">
              {{ formatDate(props.row.deliveryDate) }}
            </q-td>
            <q-td key="deliveryTime" :props="props">
              {{ props.row.deliveryTime }}
            </q-td>
            <q-td key="deliveryPlace" :props="props">
              {{ props.row.deliveryPlace }}
            </q-td>
            <q-td key="status" :props="props">
              <q-badge
                :label="formatOrderStatus(props.row.status).label"
                :color="formatOrderStatus(props.row.status).color"
              />
            </q-td>
          </q-tr>
          <q-tr v-show="props.row.expand" :props="props">
            <q-td colspan="100%">
              <div class="text-left" v-for="item in props.row.cart">
                {{ item.amount }}x - {{ item.name }} - ${{
                  item.price.toFixed(2)
                }}
                = ${{ (item.amount * item.price).toFixed(2) }}
              </div>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>

    <q-dialog v-model="studentsDialog">
      <q-card flat class="cp-rounded-borders">
        <q-card-section>
          <div class="text-h6 cp-700">
            Selecciona estudiante para crear pedido
          </div>
        </q-card-section>
        <q-separator />
        <q-card-section v-for="student in students">
          <q-btn
            :label="`${student.name} ${student.lastName}`"
            :to="`/parents/orders/new/${student.id}`"
            class="full-width cp-700 cp-rounded-borders"
            color="blue-8"
            unelevated
            no-caps
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script setup>
import {ref} from 'vue'
import {auth} from '../../firebase'

import {useRouter} from 'vue-router'

import {onAuthStateChanged} from 'firebase/auth'

import {compGetStudents} from '@/composables/getStudents'
import {compGetOrders} from '@/composables/getOrders'
import {compDataFormat} from '@/composables/dataFormat'

const {getParentStudents} = compGetStudents()
const {getParentOrders} = compGetOrders()
const {formatDate, formatOrderStatus} = compDataFormat()

const router = useRouter()

const students = ref([])
const studentsDialog = ref(false)
const orders = ref([])
const ordersLoading = ref(false)

const openNewOrderView = () => {
  if (students.value.length > 1) studentsDialog.value = true
  else router.push(`/parents/orders/new/${students.value[0].id}`)
}

onAuthStateChanged(auth, async user => {
  if (user) {
    ordersLoading.value = true
    students.value = await getParentStudents(user.uid)
    orders.value = await getParentOrders(user.uid)
    ordersLoading.value = false
  }
})

const columns = [
  {
    name: 'student',
    required: true,
    label: 'Estudiante',
    align: 'left',
    field: 'studentName',
    sortable: true,
  },
  {
    name: 'purchaseDate',
    required: true,
    label: 'Fecha de orden',
    align: 'left',
    field: 'purchaseDate',
    sortable: true,
  },
  {
    name: 'deliveryDate',
    required: true,
    label: 'Fecha de entrega',
    align: 'left',
    field: 'deliveryDate',
    sortable: true,
  },
  {
    name: 'deliveryTime',
    required: true,
    label: 'Hora de entrega',
    align: 'left',
    field: 'deliveryTime',
    sortable: true,
  },
  {
    name: 'deliveryPlace',
    required: true,
    label: 'Lugar de entrega',
    align: 'left',
    field: 'deliveryPlace',
    sortable: true,
  },
  {
    name: 'status',
    required: true,
    label: 'Estatus',
    align: 'left',
    field: 'status',
    sortable: true,
  },
]
</script>
