import {db, auth} from '../firebase'

import {collection, addDoc} from 'firebase/firestore'

import moment from 'moment'

import {compGetStudents} from '@/composables/getStudents'
import {compUpdateStudents} from '@/composables/updateStudents'
import {compGetUserData} from '@/composables/getUserData'

const {getStudentById} = compGetStudents()
const {updateStudentBalance} = compUpdateStudents()
const {getUserData} = compGetUserData()

export function compPostSales() {
  async function postSale(cart, student, pMethod) {
    const currUser = await getUserData(auth.currentUser.uid)
    const soldBy = `${currUser.name} ${currUser.lastName}`
    // 1. calculate total
    let total = 0
    cart.forEach(item => {
      total += item.amount * item.price
    })
    // 2. build sale object
    let studentId, parentId, studentName, studentLastName
    if (pMethod == 'fingerprint' || pMethod == 'rfid') {
      studentId = student.id
      parentId = student.parentId
      studentName = student.name
      studentLastName = student.lastName
    } else {
      studentId = pMethod
      parentId = pMethod
      studentName = pMethod
      studentLastName = pMethod
    }
    let saleObject = {
      total: total,
      studentId: studentId,
      parentId: parentId,
      studentName: studentName,
      studentLastName: studentLastName,
      paymentMethod: pMethod,
      date: moment().format('YYYY/MM/DD'),
      cart: cart,
      soldBy: soldBy,
    }
    if (student) {
      // 3. get student data
      const studentResp = await getStudentById(student.id)
      // 4. update student balance
      let currentBalance = studentResp.balance
      if (student.allowCredit) {
        if (currentBalance - total < -10) return 'no-balance'
        let newBalance = currentBalance - total
        await updateStudentBalance(student.id, newBalance)
      } else {
        if (total > currentBalance) return 'no-balance'
        let newBalance = currentBalance - total
        await updateStudentBalance(student.id, newBalance)
      }
    }
    // 5. post sale
    await addDoc(collection(db, 'sales'), saleObject)
    return 'ok'
  }
  return {
    postSale,
  }
}
