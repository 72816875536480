<template>
  <q-layout view="lHh lpR lFf">
    <div v-if="userType == 'admin' || userType == 'cashier'">
      <q-header class="bg-grey-2">
        <q-toolbar>
          <q-btn
            dense
            flat
            round
            icon="menu"
            color="blue-8"
            @click="toggleLeftDrawer"
          />

          <q-toolbar-title>
            <div class="text-h5 text-blue-8 cp-700">CafiPago</div>
          </q-toolbar-title>
        </q-toolbar>
      </q-header>

      <q-drawer
        show-if-above
        v-model="leftDrawerOpen"
        side="left"
        class="bg-grey-2 q-pa-md"
      >
        <div
          class="cp-rounded-borders"
          style="
            height: 100%;
            background-image: url('https://cafipago.com/pattern.png');
          "
        >
          <q-list class="q-pa-sm">
            <q-item
              clickable
              v-ripple
              v-for="(item, i) in filterMenuItems"
              :key="i"
              :to="item.route"
              :class="`${
                activeItem(item.route) ? 'bg-blue-10' : ''
              } cp-rounded-borders q-mb-sm`"
            >
              <q-item-section avatar>
                <q-icon color="white" :name="item.icon" />
              </q-item-section>

              <q-item-section class="text-subtitle2 text-white">
                {{ item.label }}
              </q-item-section>
            </q-item>
            <q-separator color="blue-5" class="q-mb-sm q-mx-sm" />
            <q-item
              clickable
              v-ripple
              to="/admin/settings"
              :class="`${
                activeItem('/admin/settings') ? 'bg-blue-10' : ''
              } cp-rounded-borders q-mb-sm`"
            >
              <q-item-section avatar>
                <q-icon color="white" name="o_settings" />
              </q-item-section>

              <q-item-section class="text-subtitle2 text-white">
                Ajustes
              </q-item-section>
            </q-item>
            <q-separator color="blue-5" class="q-mb-sm q-mx-sm" />
            <q-btn
              label="Cerrar sesión"
              class="full-width"
              color="white"
              flat
              no-caps
              icon-right="logout"
              rounded
              @click="logoutUser"
            />
          </q-list>
        </div>
      </q-drawer>

      <q-page-container>
        <router-view />
      </q-page-container>
    </div>
  </q-layout>
</template>

<script setup>
import {ref, computed, onBeforeMount} from 'vue'

import {useRoute} from 'vue-router'

import {compAuth} from '@/composables/auth'

const {logoutUser} = compAuth()

const route = useRoute()

const userType = ref(null)

onBeforeMount(() => {
  userType.value = sessionStorage.getItem('cpUserType')
})

const leftDrawerOpen = ref(false)
function toggleLeftDrawer() {
  leftDrawerOpen.value = !leftDrawerOpen.value
}

const filterMenuItems = computed(() => {
  if (userType.value == 'admin') return menuItems
  return menuItems.filter(item => !item.adminOnly)
})

const menuItems = [
  {
    label: 'POS',
    route: '/admin/pos',
    icon: 'o_store',
  },
  {
    label: 'Solicitudes de recarga',
    route: '/admin/recharges',
    icon: 'add',
    adminOnly: true,
  },
  {
    label: 'Menú',
    route: '/admin/menu',
    icon: 'restaurant_menu',
  },
  {
    label: 'Inventario',
    route: '/admin/inventory',
    icon: 'o_inventory',
  },
  {
    label: 'Pedidos',
    route: '/admin/orders',
    icon: 'o_takeout_dining',
  },
  {
    label: 'Ventas',
    route: '/admin/sales',
    icon: 'attach_money',
    adminOnly: true,
  },
  {
    label: 'Estudiantes',
    route: '/admin/students',
    icon: 'o_school',
  },
  {
    label: 'Usuarios',
    route: '/admin/users',
    icon: 'o_people',
    adminOnly: true,
  },
]

function activeItem(path) {
  return path == route.path
}
</script>
