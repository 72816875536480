<template>
  <q-page>
    <div class="q-pa-md">
      <div class="text-h4 cp-700">Estudiantes</div>
      <div class="text-body2 cp-300">
        Consulta de saldo y revisa el historial de de los estudiantes afiliados
        a tu cuenta.
      </div>
    </div>
    <div class="row">
      <div
        class="col-lg-3 col-xs-12 q-pa-md"
        v-for="student in students"
        :key="student.id"
      >
        <q-card class="cp-rounded-borders" flat>
          <q-card-section class="text-center q-pb-none">
            <q-icon name="person" size="4em" color="grey-7" />
          </q-card-section>
          <q-card-section class="text-center q-py-none">
            <div class="text-h5">
              {{ `${student.name} ${student.lastName}` }}
            </div>
          </q-card-section>
          <q-card-section class="text-center q-pt-none">
            <div class="text-caption">Saldo:</div>
            <div class="text-h5">${{ student.balance.toFixed(2) }}</div>
          </q-card-section>
          <q-separator />
          <q-card-actions>
            <q-btn
              label="Ver historial"
              class="full-width"
              unelevated
              color="blue-8"
              flat
              rounded
              no-caps
              :to="`/parents/history?student=${student.name} ${student.lastName}`"
            />
          </q-card-actions>
        </q-card>
      </div>
      <div class="col-lg-3 col-xs-12 q-pa-md" v-if="students.length > 0">
        <q-card class="cp-rounded-borders" flat>
          <q-card-section class="q-pa-lg">
            <q-btn
              label="Agregar nuevo estudiante"
              class="full-width cp-rounded-borders"
              unelevated
              size="lg"
              no-caps
              icon="add"
              color="blue-8"
              @click="startNewStudentRegister"
            />
          </q-card-section>
        </q-card>
      </div>
    </div>
    <q-dialog v-model="newStudentDialog">
      <q-card
        flat
        class="cp-rounded-borders"
        style="width: 700px; max-width: 85vw"
      >
        <q-card-section>
          <div class="text-h6 cp-700">Agregar nuevo estudiante</div>
        </q-card-section>
        <q-separator />
        <q-card-section>
          <q-input
            label="Nombre *"
            filled
            color="blue-8"
            class="q-mb-sm"
            v-model="newStudentData.name"
          />
          <q-input
            label="Apellido *"
            filled
            color="blue-8"
            class="q-mb-sm"
            v-model="newStudentData.lastName"
          />
          <q-input
            label="Cedula *"
            filled
            color="blue-8"
            class="q-mb-sm"
            v-model="newStudentData.id"
          />
        </q-card-section>
        <q-separator />
        <q-card-actions align="right">
          <q-btn
            label="Guardar"
            unelevated
            color="green-8"
            no-caps
            class="cp-rounded-borders cp-700"
            @click="saveNewStudent"
            :loading="newStudentLoading"
            :disable="newStudentLoading || disableSaveBtn"
          />
          <q-btn
            label="Cancelar"
            unelevated
            color="grey-8"
            no-caps
            class="cp-rounded-borders cp-700"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script setup>
import {ref, computed} from 'vue'

import {auth} from '../../firebase'

import {onAuthStateChanged} from 'firebase/auth'

import {compGetStudents} from '@/composables/getStudents'
import {compPostStudents} from '@/composables/postStudents'

const {getParentStudents} = compGetStudents()
const {postNewStudent} = compPostStudents()

const students = ref([])
const newStudentDialog = ref(false)
const newStudentData = ref(null)
const newStudentLoading = ref(false)

const startNewStudentRegister = () => {
  newStudentData.value = {
    name: '',
    lastName: '',
    id: '',
    balance: 0,
  }
  newStudentDialog.value = true
}

const saveNewStudent = async () => {
  newStudentLoading.value = true
  let studentResp = await postNewStudent(newStudentData.value)
  if (studentResp == 'ok') students.value.unshift(newStudentData.value)
  newStudentLoading.value = false
  newStudentDialog.value = false
}

const disableSaveBtn = computed(() => {
  if (
    !newStudentData.value.name.trim() ||
    !newStudentData.value.lastName.trim() ||
    !newStudentData.value.id.trim()
  )
    return true
  return false
})

onAuthStateChanged(auth, async user => {
  if (user) students.value = await getParentStudents(user.uid)
})
</script>
