<template>
  <q-page>
    <div class="q-pa-md">
      <div class="text-h4 cp-700">Ajustes</div>
      <div class="text-body2 cp-300">
        Revisa tus datos y actualiza tu contraseña.
      </div>
    </div>
    <div class="row q-px-md">
      <div class="col-lg-5 col-xs-12">
        <q-card flat class="cp-rounded-borders q-mb-md" v-if="userData">
          <q-card-section>
            <div class="text-h6 cp-700">Mis datos</div>
          </q-card-section>
          <q-separator />
          <q-card-section>
            <q-input
              v-model="userData.name"
              label="Nombre"
              readonly
              filled
              color="blue-8"
              class="q-mb-sm"
            />
            <q-input
              v-model="userData.lastName"
              label="Apellido"
              readonly
              filled
              color="blue-8"
              class="q-mb-sm"
            />
            <q-input
              v-model="userData.email"
              label="Correo electrónico"
              readonly
              filled
              color="blue-8"
              class="q-mb-sm"
            />
          </q-card-section>
        </q-card>
        <q-card flat class="cp-rounded-borders">
          <q-card-section>
            <div class="text-h6 cp-700">Seguridad</div>
          </q-card-section>
          <q-separator />
          <q-card-section>
            <q-btn
              label="Solicitar cambio de contraseña"
              color="green-8"
              unelevated
              rounded
              no-caps
              class="cp-700"
              @click="sendPasswordReset"
            />
          </q-card-section>
        </q-card>
      </div>
    </div>
  </q-page>
</template>

<script setup>
import {ref} from 'vue'

import {auth} from '../../firebase'

import {onAuthStateChanged, sendPasswordResetEmail} from 'firebase/auth'

import {compGetUserData} from '@/composables/getUserData'

import {useQuasar} from 'quasar'

const $q = useQuasar()

const {getUserData} = compGetUserData()

const userData = ref(null)

const sendPasswordReset = async () => {
  try {
    await sendPasswordResetEmail(auth, userData.value.email)
    $q.notify({
      message: 'Solicitud de cambio de contraseña enviada con éxito.',
      color: 'green-8',
      icon: 'error',
      position: 'top',
      badgeColor: 'yellow-8',
      progress: true,
      timeout: 3000,
    })
  } catch (err) {
    console.log(err)
  }
}

onAuthStateChanged(auth, async user => {
  if (user) userData.value = await getUserData(user.uid)
})
</script>
