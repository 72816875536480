<template>
  <q-page>
    <div class="q-pa-md">
      <div class="text-h4 cp-700">Estudiantes</div>
      <div class="text-body2 cp-300">
        Estudiantes registrados en tu plataforma.
      </div>
    </div>

    <div class="q-px-md">
      <q-table
        :rows="allStudents"
        :filter="filter"
        :columns="columns"
        :loading="loadingData"
        row-key="name"
        flat
        class="cp-rounded-borders"
        :pagination="{rowsPerPage: 25}"
      >
        <template v-slot:top-right>
          <q-input
            borderless
            dense
            debounce="300"
            v-model="filter"
            placeholder="Buscar"
            filled
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
        <template v-slot:header="props">
          <q-tr :props="props">
            <q-th auto-width />
            <q-th auto-width />
            <q-th v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.label }}
            </q-th>
          </q-tr>
        </template>

        <template v-slot:body="props">
          <q-tr :props="props" class="text-capitalize">
            <q-td auto-width>
              <q-btn
                icon="fingerprint"
                round
                color="blue-8"
                unelevated
                @click="startFingerprintRegister(props.row)"
                v-if="isRoe"
              />
            </q-td>
            <q-td auto-width>
              <q-btn
                icon="contactless"
                round
                color="yellow-8"
                unelevated
                @click="startRFIDTagRegister(props.row)"
              />
            </q-td>
            <q-td key="allowCredit" :props="props">
              <q-toggle
                v-model="props.row.allowCredit"
                color="blue-8"
                @click="updateCreditStatus(props.row.allowCredit, props.row.id)"
              />
            </q-td>
            <q-td key="name" :props="props">
              {{ props.row.name }}
            </q-td>
            <q-td key="name" :props="props">
              {{ props.row.lastName }}
            </q-td>
            <q-td key="name" :props="props">
              ${{ props.row.balance.toFixed(2) }}
            </q-td>
            <q-td key="name" :props="props">
              {{ props.row.parentName }}
            </q-td>
            <q-td key="name" :props="props">
              {{ props.row.parentLastname }}
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
    <q-dialog v-model="registerFingerPrintDialog" persistent>
      <q-card flat class="cp-rounded-borders">
        <div v-if="!templateFound && !templateImgFound">
          <q-card-section>
            <div class="cp-rounded-borders q-py-xl text-center">
              <div class="text-h6 text-blue-8 cp-700 q-mb-md">
                Coloque el dedo sobre el lector
              </div>
              <q-spinner-rings color="blue-8" size="10em" />
            </div>
          </q-card-section>
        </div>
        <div v-else>
          <q-card-section>
            <div class="row">
              <div
                class="text-h6 text-center cp-700 text-capitalize full-width q-mb-md"
              >
                {{ `${selectedStudent.name} ${selectedStudent.lastName}` }}
              </div>
              <q-img
                :src="`data:image/jpeg;base64, ${templateImgFound}`"
                class="full-width cp-rounded-borders q-mb-md"
              />
            </div>
            <q-btn
              label="Guardar huella"
              color="green-8"
              class="full-width cp-700 cp-rounded-borders"
              unelevated
              @click="saveTemplate"
              :disable="loadingData"
              :loading="loadingData"
            />
          </q-card-section>
        </div>
        <q-separator />
        <q-card-section>
          <q-btn
            label="Cancelar registro de huella"
            color="red-7"
            outline
            class="full-width cp-rounded-borders cp-700"
            @click="cancelFingerprintRegister"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="rfidTagDialog">
      <q-card
        class="cp-rounded-borders"
        flat
        style="width: 700px; max-width: 80vw"
      >
        <q-card-section>
          <div class="text-h6 cp-700">Asignar tarjeta RFID</div>
        </q-card-section>
        <q-separator />
        <q-card-section>
          <q-input
            label="ID de tarjeta"
            filled
            color="blue-8"
            v-model="rfidTagValue"
            ref="rfidTagInput"
            @keydown.enter="completeRFIDTagRegister()"
            type="number"
            :disable="loadingData"
          />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <q-btn
            label="Guardar tarjeta"
            class="full-width cp-rounded-borders cp-700 q-mb-sm"
            no-caps
            color="green-8"
            unelevated
            @click="completeRFIDTagRegister()"
            :disable="loadingData"
            :loading="loadingData"
          />
          <q-btn
            label="Cancelar"
            class="full-width cp-rounded-borders cp-700"
            no-caps
            color="red-8"
            unelevated
            flat
            @click="cancelRFIDTagRegister()"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script setup>
import {ref, onBeforeMount} from 'vue'

import {compGetStudents} from '@/composables/getStudents'
import {compFingerprint} from '@/composables/fingerprintHandler'
import {compUpdateStudents} from '@/composables/updateStudents'

import {useQuasar} from 'quasar'

const $q = useQuasar()

let isElectron = require('is-electron')
var fileWatcher, isRoe
if (isElectron()) {
  isRoe = true
  fileWatcher = window.require('chokidar')
}

const {getAllStudents} = compGetStudents()
const {
  updateStudentFingerprintTemplate,
  updateStudentCreditStatus,
  updateStudentRFIDTag,
} = compUpdateStudents()
const {readScannedTemplate, readScannedTemplateImg} = compFingerprint()

const loadingData = ref(true)
const filter = ref('')
const allStudents = ref([])

const registerFingerPrintDialog = ref(false)
const selectedStudent = ref(null)
const templateFound = ref(null)
const templateImgFound = ref(null)
const templateWatcher = ref(null)
const templateImgWatcher = ref(null)

const rfidTagDialog = ref(false)
const rfidTagInput = ref(null)
const rfidTagValue = ref('')

const startRFIDTagRegister = student => {
  selectedStudent.value = student
  rfidTagDialog.value = true
  setTimeout(() => {
    rfidTagInput.value.focus()
  }, 50)
}

const completeRFIDTagRegister = async () => {
  loadingData.value = true
  const resp = await updateStudentRFIDTag(
    selectedStudent.value.id,
    rfidTagValue.value
  )
  loadingData.value = false
  if (resp == 'ok') {
    $q.notify({
      message: 'Tarjeta RFID guardada con éxito',
      color: 'green-8',
      icon: 'error',
      position: 'top',
      badgeColor: 'white',
      badgeTextColor: 'green-8',
      progress: true,
      timeout: 3000,
    })
    selectedStudent.value = null
    rfidTagDialog.value = false
    rfidTagValue.value = ''
  } else alert('Ocurrio un error')
}

const cancelRFIDTagRegister = () => {
  rfidTagDialog.value = false
  rfidTagValue.value = ''
  selectedStudent.value = null
}

const startFingerprintRegister = student => {
  selectedStudent.value = student
  registerFingerPrintDialog.value = true
  StartWatcher(
    'c:\\Lector de Huella\\scannedTemplate.txt',
    'c:\\Lector de Huella\\scannedTemplate.jpg'
  )
}

const cancelFingerprintRegister = () => {
  templateFound.value = null
  templateImgFound.value = null
  selectedStudent.value = null
  StopWatcher()
  registerFingerPrintDialog.value = false
}

const saveTemplate = async () => {
  loadingData.value = true
  try {
    await updateStudentFingerprintTemplate(
      selectedStudent.value.id,
      templateFound.value
    )
    $q.notify({
      message: 'Huella guardada con éxito',
      color: 'green-8',
      icon: 'error',
      position: 'top',
      badgeColor: 'white',
      badgeTextColor: 'green-8',
      progress: true,
      timeout: 3000,
    })
    cancelFingerprintRegister()
  } catch (err) {
    alert('ocurrio un error')
  }
  loadingData.value = false
}

const StartWatcher = (template, templateImg) => {
  templateWatcher.value = fileWatcher.watch(template, {
    ignored: /[\/\\]\./,
    persistent: true,
  })
  templateImgWatcher.value = fileWatcher.watch(templateImg, {
    ignored: /[\/\\]\./,
    persistent: true,
  })
  templateWatcher.value.on('change', async () => {
    let foundTemplate = await readScannedTemplate()
    templateFound.value = foundTemplate
  })
  templateImgWatcher.value.on('change', () => {
    setTimeout(async () => {
      let foundTemplateImg = await readScannedTemplateImg()
      templateImgFound.value = foundTemplateImg
    }, 100)
  })
}

const StopWatcher = () => {
  templateWatcher.value.close()
  templateImgWatcher.value.close()
}

const updateCreditStatus = async (allow, id) => {
  loadingData.value = true
  let studentResp = await updateStudentCreditStatus(allow, id)
  if (studentResp == 'ok') {
    $q.notify({
      message: `Crédito ${allow ? 'activado' : 'desactivado'} con éxito`,
      color: 'green-8',
      position: 'top',
      badgeColor: 'white',
      badgeTextColor: 'green-8',
      progress: true,
      timeout: 3000,
    })
  } else {
    $q.notify({
      message: 'Ocurrio un error',
      color: 'red-8',
      icon: 'error',
      position: 'top',
      badgeColor: 'white',
      badgeTextColor: 'green-8',
      progress: true,
      timeout: 3000,
    })
  }
  loadingData.value = false
}

onBeforeMount(async () => {
  allStudents.value = await getAllStudents()
  loadingData.value = false
})

const columns = [
  {
    name: 'allowCredit',
    required: true,
    label: 'Crédito',
    align: 'left',
    field: 'allowCredit',
    sortable: true,
  },
  {
    name: 'name',
    required: true,
    label: 'Nombre',
    align: 'left',
    field: 'name',
    sortable: true,
  },
  {
    name: 'lastName',
    required: true,
    label: 'Apellido',
    align: 'left',
    field: 'lastName',
    sortable: true,
  },
  {
    name: 'balance',
    required: true,
    label: 'Balance',
    align: 'left',
    field: 'balance',
    sortable: true,
  },
  {
    name: 'parentName',
    required: true,
    label: 'Nombre acudiente',
    align: 'left',
    field: 'parentName',
    sortable: true,
  },
  {
    name: 'parentLastname',
    required: true,
    label: 'Apellido acudiente',
    align: 'left',
    field: 'parentLastname',
    sortable: true,
  },
]
</script>
