import {db} from '../firebase'

import {doc, setDoc} from 'firebase/firestore'

import {compGetInventory} from '@/composables/getInventoryItems'

const {getInventoryItemById} = compGetInventory()

export function compPostInventory() {
  async function postInventoryItem(itemData) {
    const existingItem = await getInventoryItemById(itemData.barcode)
    if (existingItem == 'no-exists') {
      let itemObject = {
        name: itemData.name,
        price: parseFloat(itemData.price),
        category: itemData.category,
      }
      await setDoc(doc(db, 'inventory', itemData.barcode), itemObject)
      itemObject.barcode = itemData.barcode
      return itemObject
    } else return 'barcode-exists'
  }

  return {
    postInventoryItem,
  }
}
