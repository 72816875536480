<template>
  <q-page>
    <div class="q-pa-md">
      <div class="text-h4 cp-700">Inventario</div>
      <div class="text-body2 cp-300">Gestiona tu inventario de productos.</div>
      <div class="row justify-end">
        <q-btn
          label="Agregar"
          rounded
          color="green-8"
          unelevated
          no-caps
          icon-right="add"
          class="q-px-lg"
          @click="openProductDialog('new')"
        />
      </div>
    </div>

    <div class="q-px-md">
      <q-table
        :rows="allProducts"
        :filter="filter"
        :columns="columns"
        row-key="barcode"
        flat
        class="cp-rounded-borders"
        :pagination="{rowsPerPage: 25}"
        :loading="inventoryLoading"
      >
        <template v-slot:body-selection="scope">
          <q-toggle v-model="scope.selected" />
        </template>
        <template v-slot:top-right>
          <q-input
            borderless
            dense
            debounce="300"
            v-model="filter"
            placeholder="Buscar"
            filled
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
        <template v-slot:header="props">
          <q-tr :props="props">
            <q-th auto-width />
            <q-th v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.label }}
            </q-th>
          </q-tr>
        </template>

        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td auto-width>
              <q-btn
                color="yellow-8"
                icon="edit"
                size="xs"
                @click="openProductDialog('edit', props.row)"
                unelevated
                round
              />
            </q-td>
            <q-td key="name" :props="props">
              {{ props.row.name }}
            </q-td>
            <q-td key="name" :props="props">
              ${{ parseFloat(props.row.price).toFixed(2) }}
            </q-td>
            <q-td key="category" :props="props">
              {{ formatOptions(productCategories(), props.row.category) }}
            </q-td>
            <q-td key="name" :props="props">
              {{ props.row.barcode }}
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>

    <q-dialog v-model="itemDialog">
      <q-card
        flat
        class="cp-rounded-borders"
        style="width: 700px; max-width: 85vw"
      >
        <q-card-section>
          <div class="text-h5 cp-700">
            {{ dialogSaveAction == 'new' ? 'Agregar' : 'Editar' }} producto
          </div>
        </q-card-section>
        <q-separator />
        <q-card-section>
          <q-input
            label="Nombre del producto *"
            filled
            color="blue-8"
            class="q-mb-sm"
            v-model="productData.name"
            :rules="[val => !!val.trim() || 'Este campo es obligatorio']"
            hide-bottom-space
          />
          <q-input
            label="Precio *"
            type="number"
            filled
            color="blue-8"
            class="q-mb-sm"
            v-model="productData.price"
            :rules="[val => !!val || 'Este campo es obligatorio']"
            hide-bottom-space
          />
          <q-select
            label="Categoria *"
            filled
            color="blue-8"
            class="q-mb-sm"
            v-model="productData.category"
            :rules="[val => !!val || 'Este campo es obligatorio']"
            hide-bottom-space
            emit-value
            map-options
            :options="productCategories()"
          />
          <q-input
            label="Código de barras *"
            filled
            color="blue-8"
            v-model="productData.barcode"
            :disable="dialogSaveAction == 'edit'"
            :rules="[val => !!val.trim() || 'Este campo es obligatorio']"
            hide-bottom-space
          />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="row items-center justify-between">
            <q-btn
              icon="delete"
              label="Eliminar"
              rounded
              color="red-8"
              flat
              class="cp-700 q-px-lg q-ml-xs"
              no-caps
              :disable="inventoryLoading"
              :loading="inventoryLoading"
              size="sm"
              @click="deleteFromInventory(productData.barcode)"
              v-if="dialogSaveAction == 'edit'"
            />
            <div v-else />
            <div>
              <q-btn
                label="Guardar"
                unelevated
                color="green-8"
                rounded
                class="cp-700 q-px-lg q-mr-xs"
                no-caps
                @click="saveAction"
                :disable="inventoryLoading"
                :loading="inventoryLoading"
              />
              <q-btn
                label="Cancelar"
                unelevated
                color="grey-8"
                rounded
                class="cp-700 q-px-lg q-ml-xs"
                no-caps
                outline
                v-close-popup
              />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script setup>
import {ref, onBeforeMount} from 'vue'

import {compGetInventory} from '@/composables/getInventoryItems'
import {compPostInventory} from '@/composables/postInventoryItems'
import {compUpdateInventory} from '@/composables/updateInventoryItems'
import {compDeleteInventory} from '@/composables/deleteInventoryItems'
import {compDataFormat} from '@/composables/dataFormat'
import {useQuasar} from 'quasar'

const $q = useQuasar()

const {getAllInventory} = compGetInventory()
const {postInventoryItem} = compPostInventory()
const {updateInventoryItem} = compUpdateInventory()
const {deleteInventoryItem} = compDeleteInventory()
const {productCategories, formatOptions} = compDataFormat()

const filter = ref('')
const itemDialog = ref(false)
const dialogSaveAction = ref('')
const productData = ref({
  name: '',
  price: null,
  category: '',
  barcode: '',
})
const allProducts = ref([])
const inventoryLoading = ref(true)

const saveAction = () => {
  if (dialogSaveAction.value == 'new') sendNewItem()
  if (dialogSaveAction.value == 'edit') updateItem()
}

const sendNewItem = async () => {
  if (!validateInventoryForm()) return
  inventoryLoading.value = true
  const itemResp = await postInventoryItem(productData.value)
  if (itemResp == 'barcode-exists') {
    inventoryLoading.value = false
    alert('Este codigo de barras ya existe')
    return
  }
  allProducts.value.unshift(itemResp)
  itemDialog.value = false
  inventoryLoading.value = false
}

const deleteFromInventory = async id => {
  inventoryLoading.value = true
  try {
    let inventoryResp = await deleteInventoryItem(id)
    allProducts.value = allProducts.value.filter(
      item => item.barcode != inventoryResp
    )
  } catch (err) {
    alert('Ocurrio un error')
  }
  itemDialog.value = false
  inventoryLoading.value = false
}

const updateItem = async () => {
  if (!validateInventoryForm()) return
  inventoryLoading.value = true
  await updateInventoryItem(productData.value)
  itemDialog.value = false
  inventoryLoading.value = false
}

const openProductDialog = (action, product) => {
  dialogSaveAction.value = action
  if (action == 'edit') productData.value = product
  if (action == 'new') {
    productData.value = {
      name: '',
      price: null,
      barcode: '',
    }
  }
  itemDialog.value = true
}

const validateInventoryForm = () => {
  let msg = []
  if (
    !productData.value.name.trim() ||
    !productData.value.price ||
    !productData.value.barcode ||
    !productData.value.category
  )
    msg.push('Debes llenar los campos obligatorios.')
  if (productData.value.price <= 0)
    msg.push('El precio no puede ser menor o igual a 0.')
  if (msg.length > 0) {
    msg.forEach(msg => {
      $q.notify({
        message: msg,
        color: 'red-8',
        icon: 'error',
        position: 'top',
        badgeColor: 'yellow-8',
        progress: true,
        timeout: 3000,
      })
    })
    return false
  }
  return true
}

onBeforeMount(async () => {
  const invItemsResp = await getAllInventory()
  inventoryLoading.value = false
  allProducts.value = invItemsResp
})

const columns = [
  {
    name: 'name',
    required: true,
    label: 'Producto',
    align: 'left',
    field: 'name',
    sortable: true,
  },
  {
    name: 'price',
    required: true,
    label: 'Precio',
    align: 'left',
    field: 'price',
    sortable: true,
  },
  {
    name: 'category',
    required: true,
    label: 'Categoría',
    align: 'left',
    field: 'category',
    sortable: true,
  },
  {
    name: 'barcode',
    required: true,
    label: 'Código de barras',
    align: 'left',
    field: 'barcode',
    sortable: true,
  },
]
</script>
